import React from "react"
import Layout from "../components/layout"
import AboutPageComponent from "../components/about/AboutPageComponent"

import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

export default function OmPage() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 13 }) {
        id
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        omSlagthusetInfo {
          ingressOmslagthuset
          rubrikIngressOmslagthuset
          underrubrikSidhuvudOmslagthuset
          brodtextOmslagthuset
          historiskBildOmslagthuset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const page = data.wpPage
  return (
    <Layout>
      <SEO title={data.wpPage.title} />
      <>
        <AboutPageComponent lang="sv" page={page} />
      </>
    </Layout>
  )
}
