import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import HeaderFeaturedImage from "../common/HeaderFeaturedImage"

export default function AboutPageComponent({ page, lang }) {
  const { title, featuredImage, omSlagthusetInfo } = page

  const {
    ingressOmslagthuset,
    rubrikIngressOmslagthuset,
    underrubrikSidhuvudOmslagthuset,
    brodtextOmslagthuset,
    historiskBildOmslagthuset,
  } = omSlagthusetInfo

  return (
    <div>
      {featuredImage && (
        <HeaderFeaturedImage
          featuredImage={featuredImage}
          title={title}
          subTitle={underrubrikSidhuvudOmslagthuset || ""}
        />
      )}
      <div className="page-inner flex flex-col gap-4 lg:gap-8">
        <div className="text-center">
          <h2 className="text-2xl lg:text-4xl text-primary">
            {rubrikIngressOmslagthuset}
          </h2>
        </div>
        <div className="lead max-w-4xl mx-auto text-center  ">
          <p>{ingressOmslagthuset}</p>
        </div>
        <div className="max-w-4xl mx-auto">
          <div
            className="wp-content columns-2"
            dangerouslySetInnerHTML={{ __html: brodtextOmslagthuset }}
          />
        </div>
        <div className="max-w-6xl mx-auto">
          <GatsbyImage
            image={getImage(
              historiskBildOmslagthuset.localFile.childImageSharp
            )}
            className="w-full"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}
